import * as React from 'react';
import Typography from '@mui/material/Typography';
import HeroLayout from './HeroLayout';

// Assets
import passaportiImage from '../assets/passaporti-compression.webp'

export default function ProductHero() {

  const location = window.location.pathname;

  return (
    <HeroLayout
      sxBackground={{
        backgroundImage: `url(${passaportiImage})`,
        backgroundColor: '#A8415B', // Average color of the background image.
        backgroundPosition: 'center',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        height: '80vh',
      }}
    >
      {/* <img
        style={{ display: 'flex' }}
        src={backgroundImage}
        alt="Stack of italian passports"
      /> */}

      <Typography color="inherit" align="center" variant="h2">
        Conquiste já o seu passaporte italiano!
      </Typography>

      <Typography
        color="inherit"
        align="center"
        variant="h3"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        { location.includes('monitoring') ? 'Monitoramento' : location.includes('appointment') ? 'Agendamento' : 'Conheça nossos serviços' }
      </Typography>

    </HeroLayout>
  );
}