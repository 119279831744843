export const email = 'contato@ciaoprenotami.com'
export const site = 'CiaoPrenotami.com'
export const phoneNumber = '+55 (41) 9 9223-6492'
export const name = 'Ciao, Prenotami!'
export const linkWA = 'https://wa.me/message/RXYQCY6DNQNRJ1'

export const consulates = {
  "Brazil": [
      {
        'consulate': 'Curitiba',
        'regions': 'PR e SC',
        'opportunityWindow': '2 minutos',
        'monitoringFrequency': 'A cada 1 minuto'
      },
      {
        'consulate': 'São Paulo',
        'regions': 'SP, MS, MT, RO e AC',
        'opportunityWindow': '10-30 minutos',
        'monitoringFrequency': 'A cada 5 minutos'
      },
      {
        'consulate': 'Belo Horizonte',
        'regions': 'MG',
        'opportunityWindow': '20-60 minutos',
        'monitoringFrequency': 'A cada 5 minutos'
      }
  ],
  "Argentina": [{},{},{}],
}
