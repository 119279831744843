import React from 'react';
import Hero from '../components/Hero';
import TimeLineAppointment from '../components/TimeLineAppointment';
import IntroAppointment from '../components/IntroAppointment';
import FAQAppointment from '../components/FAQAppointment';

// Firebase
import analytics from '../utils/firebase';
import { logEvent } from "firebase/analytics";

export default function PassportAppointment() {

  logEvent(analytics, 'passport_appointment_screen_view')

  return (
    <div>
      <Hero />
      <IntroAppointment />
      <TimeLineAppointment />
      <FAQAppointment />
    </div>
  );
}
