import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

export default function FAQMonitoring() {
  return (
    <Container sx={{ mt:12, mb:4 }}>
      <Typography variant="h4" align="center" sx={{ mt: 8, mb: 4 }}>
        Perguntas Frequentes
      </Typography>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Como vocês sabem quando surge alguma disponibilidade?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nós possuimos um sistema informatizado que monitora o Prenot@mi. Quando detectamos disponibilidade, o próprio sistema notifica nossos clientes em um piscar de olhos.
            <br/>
            É importante ressaltar que NÃO possuimos vínculos de qualquer natureza com autoridades italianas, consulados, funcionários consulares ou servidores públicos italianos.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>A CiaoPrenotami oferece opções de agendamentos para obter cidadania?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Infelizmente, não. A CiaoPrenotami oferece agendamento e monitoramento apenas para o passaporte italiano. Para isso é preciso que a cidadania já tenha sido reconhecida e que o cadastro no AIRE já tenha sido realizado e esteja com dados atualizados.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Existe parceria entre a CiaoPrenotami e consulados?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Não! Não possuimos qualquer vínculo com autoridades italianas, consulados ou servidores públicos. Tanto o serviço de agendamento como o de monitoramento do sistema Prenot@mi utiliza a mesma via pública comum disponível aos demais usuários através do site <Link color="inherit" href="https://prenotami.esteri.it" target="_blank">prenotami.esteri.it</Link>.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Além do monitoramento de disponibilidade do passaporte, vocês monitoram disponibilidade de agendamentos para cidadania?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Por enquanto, os agendamentos de cidadania ainda não são contemplados. A CiaoPrenotami oferece monitoramento de vagas apenas para o passaporte italiano. 
          </Typography>
        </AccordionDetails>
      </Accordion>

    </Container>
  );
}
