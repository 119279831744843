import React from 'react';
import Hero from '../components/Hero';
import MonitoringTimeLine from '../components/TimeLineMonitoring';
import FAQMonitoring from '../components/FAQMonitoring';
import IntroMonitoring from '../components/IntroMonitoring';

// Firebase
import analytics from '../utils/firebase';
import { logEvent } from "firebase/analytics";

function PassportMonitoring() {
  
  logEvent(analytics, 'passport_monitoring_screen_view')

  return (
    <div>
      <Hero />
      <IntroMonitoring />
      <MonitoringTimeLine />
      <FAQMonitoring />
    </div>
  );
}

export default PassportMonitoring;