import React from 'react';
import Hero from '../components/Hero';
import Products from '../components/Products';
import Benefits from '../components/Benefits';
import FAQ from '../components/FAQ';

// Firebase
import analytics from '../utils/firebase';
import { logEvent } from "firebase/analytics";



function Home() {

  logEvent(analytics, 'home_screen_view')

  return (
    <div>
      <Hero />
      <Benefits />
      <Products />
      <FAQ />
    </div>
  );
}

export default Home;