import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { email, phoneNumber, linkWA } from '../utils/data';

export default function FAQ() {
  return (
    <Container sx={{ mt:20, mb:4 }}>
      <Typography variant="h4" align="center" sx={{ mt: 8, mb: 4 }}>
        Perguntas Frequentes
      </Typography>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Ainda não tenho cidadania italiana reconhecida. Posso solicitar o passaporte italiano?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Infelizmente, não. Para solicitar a emissão do passaporte, o solicitante deve ter não apenas a cidadania italiana já reconhecida via consulado ou via judicial; bem como sua inscrição no AIRE (Anagrafe Italiani Residenti all'Estero) atualizada.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>A CiaoPrenotami oferece opções de agendamentos para obter cidadania?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Infelizmente, não. A CiaoPrenotami oferece agendamento e monitoramento apenas para o passaporte italiano. Para isso é preciso que a cidadania já tenha sido reconhecida e que o cadastro no AIRE (<Link color="inherit" target="_blank" href="https://serviziconsolari.esteri.it/">FAST.it</Link>), bem como na unidade consular (<Link color="inherit" target="_blank" href="https://prenotami.esteri.it/">Prenot@mi</Link>), já tenham sido realizados e estejam atualizados.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Existe parceria entre a CiaoPrenotami e consulados?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            NÃO! NÃO possuimos vínculos de qualquer natureza com autoridades italianas, consulados, funcionários consulares ou servidores públicos italianos. Tanto o serviço de agendamento como o de monitoramento do sistema Prenot@mi, utilizam a mesma via pública comum disponível aos demais usuários através do site <Link color="inherit" href="https://prenotami.esteri.it" target="_blank">prenotami.esteri.it</Link>.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Ainda tenho dúvidas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            É só entrar em contato conosco. Pode enviar um e-mail para {`${email}`}.
          </Typography>
        </AccordionDetails>
      </Accordion>


    </Container>
  );
}
