import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import ConsulatesTable from './ConsulateTables';


export default function IntroMonitoring() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography id="monitoring" variant="h4" align="center" sx={{ mb: 4}}>
        Monitoramento de disponibilidade de vagas para agendamento de passaporte
      </Typography>

      <Stack spacing={2} sx={{ mt: 5, mb: 5, fontSize: 18 }}>
        <Typography id="monitoring" variant="body1" align="justify" sx={{ margin: "0 auto", fontSize: 16 }}>
          O monitoramento é a opção ideal para quem prefere realizar o agendamento por conta própria mas não dispõe de tempo suficiente para permanecer consultando vagas no Prenot@mi.
        </Typography>

        <Typography id="monitoring" variant="body1" align="justify" sx={{ mb: 4, fontSize: 16 }}>
          Nosso sistema informatizado monitora a disponibilidade de vagas no Prenot@mi e assim que novas vagas são disponibilizadas o sistema envia alertas aos nossos clientes por meio de grupos de Telegram.
        </Typography>

        <Typography id="monitoring" variant="body1" align="justify" sx={{ mb: 4, fontSize: 16 }}>
          O monitoramento é realizado com frequência variada: de uma vez por minuto em consulados com vagas mais concorridas; até uma vez a cada 5 minutos em consulados com menor demanda. Com esta frequência, a chance de você perder uma oportunidade é mínima. Assim que novas vagas são disponibilizadas o sistema envia alertas aos nossos clientes por meio de grupos do Telegram. Em breve mais formas de notificação serão adicionadas.
        </Typography>

        <Typography id="monitoring" variant="body1" align="justify" sx={{ margin: "0 auto", fontSize: 16 }}>
          Confira as unidades consulares em que oferecemos o monitoramento: 
        </Typography>

        <ConsulatesTable />

        <Typography id="monitoring" variant="body1" align="justify" sx={{ mb: 4, fontSize: 16 }}>
          É importante saber que diferentes unidades consulares têm diferentes políticas e horários de disponibilização de vagas; e que algumas possuem residindo sob seu território de atuação, um número de italianos muito maior ao que a estrutura consular permite atender. Por isso, a janela de oportunidade para o agendamento em alguns consulados costuma se fechar muito mais rapidamente do que em outras (como Curitiba, por exemplo, que costuma ter suas vagas de passaporte esgotadas em menos de 2 minutos).
        </Typography>

      </Stack>

    </Container>
  )
}