import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Icon from '@mui/material/Icon';
import topic from '@mui/icons-material/ArrowRight';
import { name } from '../utils/data';


function About() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography
        color="inherit"
        align="center"
        variant="h4"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Sobre
      </Typography>

      <Typography
        color="inherit"
        align="justify"
        variant="h6"
      >
        {name} é uma iniciativa criada por cidadãos italianos para cidadãos italianos. Nossa missão é ajudar você a conquistar seu passaporte com celeridade.
        Nós possuímos sistema informatizado de ponta e um time dedicado de especialistas alocados em diferentes fusos horários para oferecer uma cobertura de 24/7 e realizar o seu agendamento com agilidade.
        <br />
        Nós NÃO possuimos qualquer tipo de vínculo ou relação com autoridades ou órgãos italianos, consulados ou servidores públicos consulares. Os serviços prestados são realizados pela via pública comum disponível aos demais usuários através do site <a href="https://prenotami.esteri.it" target="_blank" rel="noreferrer">prenotami.esteri.it</a>.
      </Typography>

      <br />

      <Typography
        color="inherit"
        align="justify"
        variant="h6"
      >
        Atualmente, oferecemos duas opções de serviços.
      </Typography>


      <List>
          <Typography
            align="justify"
            variant="h6"
            sx={{ ml: 5 }}
          >
            <Icon component={ topic }/><strong>Monitoramento de disponibilidade de agendamento</strong>: Nós monitoramos frequentemente a abertura de novas vagas e te avisamos quando o consulado de sua preferência disponibilizar agendamentos.
          </Typography>
          <Typography
            align="justify"
            variant="h6"
            sx={{ ml: 5 }}
          >
            <Icon component={topic} /><strong>Agendamento do passaporte</strong>: Você nos envia os documentos necessários e nós cuidamos do seu agendamento. Você efetua o pagamento somente após a confirmação de que o agendamento foi realizado.
          </Typography>
      </List>

      <Typography
        color="inherit"
        align="justify"
        variant="h6"
      >
        Ficou com alguma dúvida? Entre em contato para saber mais.
      </Typography>


      
    </Container>
  );
}

export default About;