import * as React from 'react';
import Typography from '@mui/material/Typography';
import HeroLayout from './HeroLayout';

// Assets
import passaportiImage from '../assets/passaporti-compression.webp'

export default function HeroApproved() {

  return (
    <HeroLayout
      sxBackground={{
        backgroundImage: `url(${passaportiImage})`,
        backgroundColor: '#A8415B', // Average color of the background image.
        backgroundPosition: 'center',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        height: '80vh',
      }}
    >

      <Typography color="inherit" align="center" variant="h2">
        Pagamento aprovado!
      </Typography>

      <Typography
        color="inherit"
        align="center"
        variant="h3"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Cada vez mais perto de conquistar o seu passaporte!
      </Typography>

    </HeroLayout>
  );
}