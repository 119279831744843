import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import ChecklistIcon from '@mui/icons-material/Checklist';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function MonitoringTimeLine() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography id="serviços" variant="h4" align="center" component="h2" sx={{ mb: 4 }}>
        Como contrato o monitoramento?
      </Typography>

      <Timeline position="alternate" sx={{ mt: 10 }}>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <AttachMoneyIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
              1º passo
            </Typography>
            <Typography>Entre em contato por e-mail e solicite o serviço de monitoramento.</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <ChecklistIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
              2º passo
            </Typography>
            <Typography>Em seguida, providenciaremos as informações para você realizar o pagamento.</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            variant="body2"
            color="text.secondary"
          >
            Por enquanto oferecemos apenas grupo de Telegram. Estamos trabalhando para oferecer mais opções em breve.
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <QuickreplyIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
            2º passo
            </Typography>
            <Typography>Com o pagamento confirmado, te enviamos o link de acesso ao grupo e você passa a receber avisos de disponibilidade para agendar o seu passaporte. Mas seja rápido! A janela de oportunidade costuma ser bem breve.</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <RepeatIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
              4º passo
            </Typography>
            <Typography>Se você não conseguir, fique tranquilo! Avisaremos mais vezes ao decorrer do mês e todos os consulados abrem vagas frequentemente.</Typography>
          </TimelineContent>
        </TimelineItem>

      </Timeline>
    </ Container>
  );
}
