import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { consulates } from '../utils/data';

export default function ConsulatesTable() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <TableContainer component={ Paper } sx={{ minWidth: 800, maxWidth: 1000, alignContent: "justify" }}>
        <Table sx={{ minWidth: 800, maxWidth: 1000 }} size='small' aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: 16 }}>* Unidade consular</TableCell>
              <TableCell align="right" sx={{ fontSize: 16 }}>Regiões atendidas</TableCell>
              <TableCell align="right" sx={{ fontSize: 16 }}>** Duração da janela de oportunidade</TableCell>
              <TableCell align="right" sx={{ fontSize: 16 }}>Frequência do monitoramento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consulates["Brazil"].map((row) => (
              <TableRow
                key={row.consulate}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ fontSize: 14 }}>
                  {row.consulate}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: 14 }}>{row.regions}</TableCell>
                <TableCell align="right" sx={{ fontSize: 14 }}>{row.opportunityWindow}</TableCell>
                <TableCell align="right" sx={{ fontSize: 14 }}>{row.monitoringFrequency}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <br />

      <Typography id="monitoring" variant="body1" align="justify" sx={{ margin: "0 auto", fontSize: 14  }}>
        * As unidades consulares italianas no Brasil que não foram mencionadas na tabela permitem o agendamento do passaporte sem restrição de horário, seja por e-mail ou pelo próprio Prenot@mi. Nesses casos, o monitoramento não se faz necessário.
      </Typography>

      <Typography id="monitoring" variant="body1" align="justify" sx={{ margin: "0 auto", fontSize: 14  }}>
        ** Duração média aproximada do tempo que os agendamentos permanecem disponíveis.
      </Typography>

    </Container>
  );
}