import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Assets
import curvyLines from '../assets/curvyLines.png'
import productValues1 from '../assets/productValues1.svg'
import productValues2 from '../assets/productValues2.svg'
import productValues3 from '../assets/productValues3.svg'


const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

export default function Benefits() {
  return (
    
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden' }}
    >
      
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        
        <Box
          component="img"
          src={`${curvyLines}`}
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />

        <Grid container spacing={5}>

          <Grid item xs={12} md={4}>

            <Box sx={item}>

              <Box
                component="img"
                src={`${productValues1}`}
                alt="suitcase"
                sx={{ height: 55 }}
              />

              <Typography variant="h5" sx={{ my: 5 }}>
                SEM PREOCUPAÇÃO
              </Typography>

              <Typography variant="h6" align="justify">
                {'Basta de se preocupar e perder seu tempo consultando vagas no Prenot@mi. Nós fazemos isso por você, 24 horas por dia, 7 dias por semana.'}
              </Typography>

            </Box>

          </Grid>

          <Grid item xs={12} md={4}>
            
            <Box sx={item}>

              <Box
                component="img"
                src={`${productValues2}`}
                alt="graph"
                sx={{ height: 55 }}
              />

              <Typography variant="h5" sx={{ my: 5 }}>
                CUIDAMOS DE TUDO
              </Typography>

              <Typography variant="h6" align="justify">
                {'Você escolhe: '}
                
                {/* <Link color="inherit" href='/passport_appointment'> */}
                {' Nós cuidamos de todo o processo de agendamento para você'}
                {/* </Link> */}

                {'; ou '}
               
                {/* <Link color="inherit" href='/passport_monitoring'> */}
                {'te avisamos quando o consulado de sua preferência disponibiliza agendamentos.'}
                {/* </Link> */}
              </Typography>

            </Box>

          </Grid>

          <Grid item xs={12} md={4}>

            <Box sx={item}>

              <Box
                component="img"
                src={`${productValues3}`}
                alt="clock"
                sx={{ height: 55 }}
              />

              <Typography variant="h5" sx={{ my: 5 }}>
                MELHORES PREÇOS
              </Typography>

              <Typography variant="h6" align="justify">
                {'Seja para agendamento ou monitoramento, os valores que praticamos são os mais atrativos do mercado.'}
              </Typography>

            </Box>

          </Grid>

        </Grid>

      </Container>

    </Box>
  );
}
