import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import ChecklistIcon from '@mui/icons-material/Checklist';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function MonitoringTimeLine() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography id="serviços" variant="h4" align="center" component="h2" sx={{ mb: 4 }}>
        Como contrato o agendamento?
      </Typography>

      <Timeline position="alternate" sx={{ mt: 10 }}>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            Sinta-se a vontade para entrar em contato por e-mail e esclarecer eventuais dúvidas. Ficaremos mais que felizes em atendê-lo(a).
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <AttachMoneyIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
              1º passo
            </Typography>
            <Typography>Manifestar interesse de nos contratar para efetuar o seu agendamento.</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            variant="body2"
            color="text.secondary"
          >
            Leia tudo com atenção! Eventuais alterações nas informações submetidas podem acarretar em atrasos no agendamento.
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <QuickreplyIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
            2º passo
            </Typography>
            <Typography>Em seguida nós enviaremos o contrato a ser assinado; e um formulário a ser preenchido com seus dados, juntamente com informações para preencher corretamente.</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
        <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            Fique tranquilo, adotamos as melhores políticas de segurança para proteger os seus dados. E após o pagamento, deletamos tudo seguindo as melhores práticas.
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <ChecklistIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
              3º passo
            </Typography>
            <Typography>Você nos envia o contrato assinado e o formulário preenchido.</Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
        <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            Sempre te manteremos atualizado(a) sobre as tentativas de agendamento.
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <RepeatIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
              4º passo
            </Typography>
            <Typography>Agora é só aguardar. Em breve entraremos em contato com a confirmação do seu agendamento e a solicitação do pagamento nos termos contratados.</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </ Container>
  );
}
