import React from 'react';
import Grid from "@mui/material/Grid";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import { CardActionArea, CardHeader } from '@mui/material';


const images = [
  {
    title: 'Monitoramento',
    width: '50%',
    link: '/passport_monitoring',
    description:`Nós te avisamos quando surgir disponibilidade no seu consulado e você faz o próprio agendamento.`,
  },
  {
    title: 'Agendamento',
    width: '50%',
    link: '/passport_appointment',
    description:`Você nos fornece os dados necessários e nós cuidamos do agendamento do seu passaporte para você.`,
  }
];

export default function Products() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography id="serviços" variant="h4" align="center" sx={{ mb: 4 }}>
        Serviços ofertados
      </Typography>
      <Grid container spacing={5} direction={"row"}  sx={{ mt: 4, mb: 4 }}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} align="center" key={index}>

            <Card sx={{ maxWidth: 350 }}>

              <CardActionArea>

                <Link href={image.link} color="inherit" underline="none">

                  <CardHeader title={image.title}></CardHeader>

                  <CardContent>
                    <Typography color="text.secondary">
                      {image.description}
                    </Typography>
                  </CardContent>

                </Link>

              </CardActionArea>

            </Card>
          </Grid>

        ))}
      </Grid>
    </Container>
  );
}
