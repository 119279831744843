import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';


export default function PaymentApproved() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>

      <Stack spacing={2} sx={{ mt: 5, mb: 5, fontSize: 18 }}>
        <Typography id="monitoring" variant="body1" align="justify" sx={{ margin: "0 auto", fontSize: 16 }}>
          Agora é só enviar uma mensagem aqui e solicitar a sua inclusão no grupo de monitoramento do consulado de sua preferência.
        </Typography>
      </Stack>

    </Container>
  )
}