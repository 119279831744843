import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/Navigation';
import Footer from './components/Footer';
import Home from "./pages/Home";
import PassportAppointment from "./pages/PassportAppointment";
import PassportMonitoring from "./pages/PassportMonitoring";
import About from "./pages/About";
import MonitoringPaymentApproved from './pages/MonitoringPaymentApproved';


function App() {
  return (
    <BrowserRouter>

      <NavigationBar />

      <Routes>
        <Route element={ <About/> } path="/sobre" />
        <Route element={ <PassportAppointment/> } path="/passport_appointment" />
        <Route element={ <PassportMonitoring/> } path="/passport_monitoring" />
        <Route element={ <Home/> } path="/" />
        <Route element={ <MonitoringPaymentApproved/> } path="/monitoring_payment_approved" />
      </Routes>

      <Footer />
      
    </BrowserRouter>
  );
}

export default App;