import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';


export default function IntroAppointment() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography id="appointment" variant="h4" align="center" component="h2" sx={{ mb: 4 }}>
        Agendamento de passaporte
      </Typography>

      <Stack spacing={2} sx={{ mt: 5, mb: 5 }}>
        <Typography id="appointment" variant="h6" align="center" component="h2" sx={{ margin: "0 auto" }}>
          Esta é a opção para quem quer ZERO preocupação com o agendamento do passaporte. Apenas é necessário nos enviar seus dados, bem como documentos digitalizados e nós cuidaremos de todo o resto, sempre enviando atualizações sobre o progresso do seu agendamento.
        </Typography>

        <Typography id="appointment" variant="h6" align="center" component="h2" sx={{ margin: "0 auto" }}>
          O melhor é que você só efetua o pagamento após receber a confirmação de que seu agendamento foi realizado com sucesso.
        </Typography>

        <Typography id="appointment" variant="h6" align="center" component="h2" sx={{ mb: 4 }}>
          Para agendarmos o seu passaporte com a maior celeridade, utilizamos nossa própria estrutura de monitoramento das vagas e nos comprometemos a efetuar tentativas diárias, sempre que detectarmos uma disponibilidade no seu consulado.
        </Typography>

        <Typography id="appointment" variant="h6" align="center" component="h2" sx={{ mb: 4 }}>
          As informações e os documentos necessários para agendamento do seu passaporte, bem como a possibilidade de agendamento multiplo (até 5 pessoas por agendamento desde que formem um mesmo núcleo familiar) dependem do consulado.
        </Typography>

        <Typography id="appointment" variant="h6" align="center" component="h2" sx={{ mb: 4 }}>
          
        </Typography>
    
      </Stack>

    </Container>
  )
}