import React from 'react';

// Firebase
import analytics from '../utils/firebase';
import { logEvent } from "firebase/analytics";
import HeroApproved from '../components/HeroApproved';
import PaymentApproved from '../components/PaymentApproved';

function MonitoringPaymentApproved() {
  
  logEvent(analytics, 'monitoring_payment_approved_screen_view')

  return (
    <div>
      <HeroApproved />
      <PaymentApproved />
    </div>
  );
}

export default MonitoringPaymentApproved;