import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram } from "@mui/icons-material";
import { Box } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import { email, name, site } from '../utils/data';
import { useTranslation } from 'react-i18next'


export default function Footer() {
  const { t } = useTranslation();

  return (
    <Box
      component="footer"
      position="static"
      mt={3}
      pb={10}
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
      style={{ backgroundColor: "#3a3a3a", color: "#fff", borderTop: "2px solid transparent", borderImageSlice: "1", borderImageSource: "linear-gradient(to right, green, white, red)"}}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>

          <Grid item xs={12} sm={6.8} md={3.8} lg={3}>
            <Typography color="palette.primary.light" gutterBottom>
              Sobre
            </Typography>
            <Typography variant="body1" color="palette.primary.light" align="justify">
              {`${name}`} é uma iniciativa realizada por italianos, para italianos. Nossa missão é ajudar você a conquistar seu passaporte com facilidade e celeridade.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={5.2} md={3.2} lg={2.5}>
            <Typography color="palette.primary.light" gutterBottom>
              Contato
            </Typography>
            <Typography variant="body1" color="palette.primary.light">
              <EmailIcon fontSize="small" />
              {` ${email}`}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={10} md={3.8} lg={5.5}>
            <Typography color="palette.primary.light" gutterBottom>
              Isenção de responsabilidade
            </Typography>
            <Typography variant="body1" color="palette.primary.light" align="justify">
              {`${name}`} NÃO possui vínculos ou relações com autoridades italianas, consulados ou servidores públicos consulares. Os serviços prestados são realizados pela via pública comum disponível aos demais usuários através do site <Link color="inherit" href="https://prenotami.esteri.it" target="_blank">prenotami.esteri.it</Link>.
            </Typography>
          </Grid>

        </Grid>

        <Box mt={10}>
          <Typography variant="body2" color="palette.primary.light" align="center">
            {"Feito por "}
            <Link color="inherit" href={`/`}>
              {site}
            </Link>{" - "}
            {"Copyright © "}
            {new Date().getFullYear()}
            {"."}
            { t("footer.copyright.text") }
          </Typography>
        </Box>

      </Container>
    </Box>
  );
}