import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAvSyxSnDz4l7SIhvjW-MEbXh-rsDuQ0Uo",
  authDomain: "p-bot-395921.firebaseapp.com",
  projectId: "p-bot-395921",
  storageBucket: "p-bot-395921.appspot.com",
  messagingSenderId: "377831363242",
  appId: "1:377831363242:web:ead470ec0f31d2850536d3",
  measurementId: "G-QWRQG43ZQW"
};

const analytics = getAnalytics(initializeApp(firebaseConfig));

export default analytics;